import {
  ElementType,
  LineStringGeometryDto,
  MapElementDto,
  PointGeometryDto,
  PolygonGeometryDto,
} from '@cartken/map-types';
import { BehaviorSubject, Observable, Subject, distinct } from 'rxjs';
import { getMidPoint } from '../../../utils/geo-tools';
import {
  generateBlockedEdgePoints,
  generateCorridorPolygon,
  generateOnewayArrows,
} from '../map-elements/edge';
import { isSlippyTilesDto, SlippyTilesDto } from '../map-elements/slippy-tiles';
import {
  LayerName,
  getHighlightColor,
  getIcon,
  getLineColor,
  slippyTilesStyles,
  getCorridorColor,
} from './visualization-styles';
import { Layer, LayerContext } from '@deck.gl/core/typed';
import { GeoJsonLayer, IconLayer, TextLayer } from '@deck.gl/layers/typed';
import { InteractiveGeoJsonLayer } from './interactive-geojson-layer';
import { multiPoint, multiLineString, polygon } from '@turf/helpers';

import { EditAction } from './types';
import { InteractiveMode } from './interactive-mode';
import * as R from 'ramda';
import { BaseMap, MapStyle } from './base-map';
import { MapboxBaseMap } from './mapbox-base-map';
import { createTileLayer, getBounds, isEdgeBlocked } from './utils';
import { LatLngBounds } from 'spherical-geometry-js';
import {
  LocalizationMapTilesDto,
  isLocalizationMapTilesDto,
} from '../map-elements/localization-map-tiles';
import { isDefined } from '../../../utils/typeGuards';
import WebMercatorViewport from 'viewport-mercator-project';
import { TileLayer } from '@deck.gl/geo-layers/typed';
import { LocalizationMapTileLayer } from './localization-map/localization-map-tile-layer';
import { LocalizationTileset } from './localization-map/localization-tileset';
import { IconMapping } from '@deck.gl/layers/typed/icon-layer/icon-manager';
import { ZoomBoundedLayer } from './zoom-bounded-layer';

export interface MapElementMouseEvent {
  mapElementId: number;
  vertex?: number;
}

export interface MapElementGeometryChangeEvent {
  mapElementId: number;
  geometry: PointGeometryDto | LineStringGeometryDto | PolygonGeometryDto;
}

const altitudeFlatteningFactor = 1e-4;
const zoomThreshold = 18;

export class VisualizationManager {
  private readonly map: BaseMap;
  private _onChange$ = new Subject<MapElementDto[]>();
  private _selectedMapElement$ = new Subject<MapElementDto | undefined>();
  private _altitudeRange$ = new BehaviorSubject<[number, number]>([0, 100]);
  private layerStyles = structuredClone(slippyTilesStyles);
  private hiddenMapElementTypes: ElementType[] = [];
  private mapElementOpacity = 1;
  private zoom = Infinity;
  private flattenAltitudes = true;
  private minDisplayAltitude = 0;
  private maxDisplayAltitude = 1000;
  private altitudeOffset = 0;
  private hoveringOverObject = false;
  private interactiveMapElements: MapElementDto[] = [];
  private displayMapElements: MapElementDto[] = [];
  private derivedFeatures: {
    type: 'Feature';
    properties: any;
    geometry: any;
  }[] = [];
  private localizationMapElements: LocalizationMapTilesDto[] = [];
  private slippyTileMapElements: SlippyTilesDto[] = [];
  private mode: InteractiveMode = new InteractiveMode();

  readonly onChange$ = this._onChange$.asObservable();
  readonly boundsChanged$: Observable<LatLngBounds>;
  readonly selectedMapElement$ = this._selectedMapElement$.asObservable();
  readonly altitudeRange$ = this._altitudeRange$.pipe(distinct());

  private selectedMapElement: MapElementDto | undefined;

  constructor(mapContainerElement: HTMLDivElement) {
    this.map = new MapboxBaseMap(mapContainerElement);
    this.map.setProps({
      getCursor: () => (this.hoveringOverObject ? 'pointer' : 'grab'),
    });
    this.boundsChanged$ = this.map.boundsObservable();
  }

  private transformAltitude(mapElement: MapElementDto): MapElementDto {
    const altitudeFactor = this.flattenAltitudes ? altitudeFlatteningFactor : 1;
    const transformAltitude = (a?: number) =>
      a ? (a - this.altitudeOffset) * altitudeFactor : 0;
    const clone = structuredClone(mapElement);
    switch (clone.geometry.type) {
      case 'Point':
        clone.geometry.coordinates[2] = transformAltitude(
          clone.geometry.coordinates[2],
        );
        break;
      case 'LineString':
        for (let i = 0; i < clone.geometry.coordinates.length; ++i) {
          clone.geometry.coordinates[i][2] = transformAltitude(
            clone.geometry.coordinates[i][2],
          );
        }
        break;
      case 'Polygon':
        for (let i = 0; i < clone.geometry.coordinates[0].length; ++i) {
          clone.geometry.coordinates[0][i][2] = transformAltitude(
            clone.geometry.coordinates[0][i][2],
          );
        }
        break;
    }
    return clone;
  }

  private createInteractiveGeoJsonLayer() {
    const selectedIndex = this.displayMapElements.findIndex(
      (m) => m.id === this.selectedMapElement?.id,
    );

    const altitudeFactor = this.flattenAltitudes ? altitudeFlatteningFactor : 1;
    const transformAltitude = (a?: number) =>
      a ? (a - this.altitudeOffset) * altitudeFactor : 0;

    return new InteractiveGeoJsonLayer({
      id: 'interactive-geojson-layer',
      mode: this.mode,
      data: {
        type: 'FeatureCollection',
        features: this.displayMapElements,
      },
      altitudeTransformer: transformAltitude,
      mapCoordGetter: (coords, context) =>
        this.surfaceCoordinates(coords, context),
      filled: false,
      onEdit: (editAction) => this.onEdit(editAction),
      selectedFeatureIndexes: selectedIndex >= 0 ? [selectedIndex] : [],
      getLineColor: (f: MapElementDto, isSelected, interactiveMode) =>
        getLineColor(f, isSelected, interactiveMode),
      getLineWidth: (f: MapElementDto) => (f.elementType === 'Node' ? 8 : 4),
      getHighlightColor: (pickingInfo, interactiveMode) =>
        getHighlightColor(pickingInfo, interactiveMode),
      getIcon,
      getIconSize: 20,
      pointType: 'circle+icon',
      getPointRadius: 4,
      lineJointRounded: true,
      opacity: this.mapElementOpacity,
      enableMapPanning: (enablePanning) => {
        this.map.enableDragPanning(enablePanning);
      },
      onHover: (pickingInfo) => {
        const hovering = Boolean(pickingInfo.object);

        if (hovering !== this.hoveringOverObject) {
          this.hoveringOverObject = hovering;
          const cursor = hovering ? 'pointer' : 'grab';
          this.map.setProps({ getCursor: () => cursor });
        }
      },
    });
  }

  private createDerivedGeometryLayer() {
    return new GeoJsonLayer({
      id: 'derived-geometry',
      data: this.derivedFeatures,
      filled: true,
      stroked: false,
      getFillColor: (f) => f.properties?.['color'] ?? [0, 0, 0],
      getLineColor: (f) => f.properties?.['color'] ?? [0, 0, 0],
      getLineWidth: (f) => f.properties?.['width'] ?? 0.5,
      getPointRadius: (f) => f.properties?.['radius'] ?? 1,
      pointRadiusMinPixels: 10,
    });
  }

  private createDerivedLegendLayers(): Layer[] {
    const robotQueueEdges = this.displayMapElements
      .filter((m) => m.elementType === ElementType.ROBOT_QUEUE_EDGE)
      .map((m) => this.transformAltitude(m));
    const ICON_MAPPING: IconMapping = {
      marker: {
        x: 0,
        y: 0,
        width: 48,
        height: 48,
        mask: true,
        anchorY: 48,
        anchorX: 24,
      },
    };
    const getPosition = (d: MapElementDto) => {
      const midIndex = (d.geometry.coordinates.length - 1) / 2;
      if (midIndex % 1 !== 0) {
        const startPoint = d.geometry.coordinates?.[
          Math.floor(midIndex)
        ] as number[];
        const endPoint = d.geometry.coordinates?.[
          Math.ceil(midIndex)
        ] as number[];
        return getMidPoint(startPoint, endPoint);
      } else {
        const midPoint = d.geometry.coordinates[midIndex] as number[];
        midPoint[2] = midPoint[2] ?? 0;
        return midPoint as [number, number, number];
      }
    };

    return [
      new ZoomBoundedLayer({
        id: 'zoom-bounds-queue-name-layer',
        minZoom: zoomThreshold,
        renderLayers: () => [
          new TextLayer({
            getSize: 12,
            id: 'queue-name-layer',
            data: robotQueueEdges,
            getPosition,
            getText: (d) => d?.properties?.names?.join('\n'),
            getAngle: 0,
            getTextAnchor: 'start',
            getAlignmentBaseline: 'bottom',
            getPixelOffset: [10, 0],
            background: true,
            getBackgroundColor: [255, 255, 255, 150],
          }),
        ],
      }),
      new ZoomBoundedLayer({
        id: 'zoom-bounds-queue-marker-layer',
        minZoom: 14,
        renderLayers: () => [
          new IconLayer({
            id: 'queue-marker-layer',
            data: robotQueueEdges,
            iconAtlas: 'assets/baseline_location_on_black_24dp.png',
            iconMapping: ICON_MAPPING,
            getIcon: () => 'marker',
            sizeScale: 15,
            getPosition,
          }),
        ],
      }),
    ];
  }

  private createSlippyTilesLayers() {
    const layers = [];
    for (const tiles of this.slippyTileMapElements) {
      const baseUrl = tiles.properties.tilesBaseUrl;
      const name = tiles.properties.name.toLowerCase();
      const opacity = this.layerStyles[name as LayerName]?.opacity ?? 0;
      layers.push(
        createTileLayer(
          `${baseUrl}/{z}/{x}/{y}.png`,
          baseUrl,
          16,
          zoomThreshold,
          256,
          opacity,
          tiles.geometry.coordinates[0],
        ),
      );
    }
    return layers;
  }

  private createLocalizationMapLayer(
    mapElement: LocalizationMapTilesDto,
  ): Layer {
    const altitudeScale = this.flattenAltitudes ? altitudeFlatteningFactor : 1;
    const urlPath = mapElement.properties.tilesBaseUrl.split('/').at(-1);
    const bounds = getBounds(mapElement.geometry.coordinates[0]);
    bounds[0] = mapElement.properties.tilesOriginLongitude;
    bounds[1] = mapElement.properties.tilesOriginLatitude;

    return new TileLayer({
      id: `localization-map-${urlPath}`,
      TilesetClass: LocalizationTileset,
      data: mapElement.properties.tilesBaseUrl,
      minZoom: zoomThreshold,
      tileSize: mapElement.properties.tilesSize,
      maxRequests: 20, // Concurrent requests to make loading faster.
      extent: bounds,
      pickable: true,
      maxCacheSize: 100,
      zRange: [0, this.maxDisplayAltitude - this.altitudeOffset],
      renderSubLayers: (props) => {
        return new LocalizationMapTileLayer(props, {
          tileIndex: [props.tile.index.x, props.tile.index.y],
          data: mapElement.properties,
          minDisplayAltitude: this.minDisplayAltitude,
          maxDisplayAltitude: this.maxDisplayAltitude,
          altitudeScale: altitudeScale,
          altitudeOffset: this.altitudeOffset,
          layerOpacities: this.getLayerOpacities(),
        });
      },
      getTileData: () => {},
      onTileError: (e) => {},
      onTileUnload: (tile) => {
        console.log('Unloading', tile.index.x, tile.index.y);
      },
      updateTriggers: {
        renderSubLayers: [
          this.minDisplayAltitude,
          this.maxDisplayAltitude,
          altitudeScale,
          this.altitudeOffset,
        ],
      },
    });
  }

  private createLocalizationMapLayers(): Layer[] {
    return this.localizationMapElements
      .filter(isDefined)
      .map((m: LocalizationMapTilesDto) => this.createLocalizationMapLayer(m));
  }

  private surfaceCoordinates(
    screenCoords: [number, number],
    context: LayerContext,
  ): [number, number, number] {
    const pick = context.deck?.pickObject({
      x: screenCoords[0],
      y: screenCoords[1],
      unproject3D: true,
      layerIds: ['localization-map'],
    });

    const heightMapOffset = 0.1;

    if (pick?.coordinate?.length && (pick as any)?.altitude !== undefined) {
      return [
        pick.coordinate[0],
        pick.coordinate[1],
        (pick as any).altitude + heightMapOffset,
      ];
    }

    const defaultAltitude =
      (this.minDisplayAltitude + this.maxDisplayAltitude) / 2;
    const altitudeFactor = this.flattenAltitudes ? altitudeFlatteningFactor : 1;
    const transformedDefaultAltitude =
      (defaultAltitude - this.altitudeOffset) * altitudeFactor;

    // Adjust altitude so we project the intersection with the earth ellipsoid at the
    // default altitude instead of altitude===0
    const viewport = new WebMercatorViewport({
      ...context.viewport,
      position: [0, 0, -transformedDefaultAltitude],
    });

    const coords = viewport.unproject([screenCoords[0], screenCoords[1]]);
    return [coords[0], coords[1], defaultAltitude ?? 0];
  }

  getLatLngBounds(): LatLngBounds {
    return this.map.getBounds();
  }

  getZoom(): number {
    return this.map.getZoom();
  }

  fitBounds(bounds: LatLngBounds) {
    this.map.fitBounds(bounds);
  }

  setMode(mode: InteractiveMode) {
    this.mode = mode;
    this.rerenderMapElements();
  }

  enableTerrain(enabled: boolean) {
    this.map.enableTerrain(enabled);
  }

  enableAltitudeFlattening(enabled: boolean) {
    this.map.enableTerrain(false);
    this.flattenAltitudes = enabled;
    this.updateDisplayMapElements();
  }

  setDisplayAltitudeRange(minAltitude: number, maxAltitude: number) {
    this.minDisplayAltitude = minAltitude;
    this.maxDisplayAltitude = maxAltitude;
    this.updateDisplayMapElements();
  }

  setMapStyle(tileStyle: MapStyle) {
    this.map.setMapStyle(tileStyle);
  }

  setSelectedMapElement(mapElement?: MapElementDto) {
    this.selectedMapElement = mapElement;
    this.rerenderMapElements();
  }

  setHiddenMapElementTypes(hiddenElementTypes: ElementType[]) {
    if (
      hiddenElementTypes.length === this.hiddenMapElementTypes.length &&
      hiddenElementTypes.every((v) => this.hiddenMapElementTypes.includes(v))
    ) {
      return;
    }
    this.hiddenMapElementTypes = hiddenElementTypes;
    this.updateDisplayMapElements();
  }

  setMapElements(mapElements: MapElementDto[]) {
    const existingMapElements = mapElements.filter((m) => !m.deleted);
    const [slippyTiles, otherElements] = R.partition(
      (m) => isSlippyTilesDto(m),
      existingMapElements,
    );
    const [localizationMapElements, interactiveMapElements] = R.partition(
      (m) => isLocalizationMapTilesDto(m),
      otherElements,
    );

    this.slippyTileMapElements = slippyTiles as SlippyTilesDto[];
    this.slippyTileMapElements.sort((a, b) => {
      const z1 =
        this.layerStyles[a.properties.name.toLowerCase() as LayerName]
          ?.zIndex ?? 0;
      const z2 =
        this.layerStyles[b.properties.name.toLowerCase() as LayerName]
          ?.zIndex ?? 0;
      return z1 - z2;
    });

    this.localizationMapElements =
      localizationMapElements as LocalizationMapTilesDto[];

    this.interactiveMapElements = interactiveMapElements;

    let minNonZeroAltitude = Infinity;
    let maxAltitude = 0;
    const updateMinMaxAltitude = (altitude?: number) => {
      if (altitude) {
        minNonZeroAltitude = Math.min(minNonZeroAltitude, altitude);
        maxAltitude = Math.max(maxAltitude, altitude);
      }
    };
    for (const m of interactiveMapElements) {
      switch (m.geometry.type) {
        case 'Point':
          updateMinMaxAltitude(m.geometry.coordinates[2]);
          break;
        case 'LineString':
          for (const c of m.geometry.coordinates) {
            updateMinMaxAltitude(c[2]);
          }
          break;
        case 'Polygon':
          for (const c of m.geometry.coordinates[0]) {
            updateMinMaxAltitude(c[2]);
          }
          break;
      }
    }
    for (const m of this.localizationMapElements) {
      if (m.properties.minAltitude) {
        minNonZeroAltitude = Math.min(
          minNonZeroAltitude,
          m.properties.minAltitude,
        );
      }
      maxAltitude = Math.max(maxAltitude, m.properties.maxAltitude ?? 0);
    }
    if (!isFinite(minNonZeroAltitude)) {
      minNonZeroAltitude = 0;
    }
    this.altitudeOffset = minNonZeroAltitude;
    this._altitudeRange$.next([
      Math.floor(minNonZeroAltitude),
      Math.ceil(maxAltitude + 1),
    ]);
    this.updateDisplayMapElements();
  }

  private onEdit(editAction: EditAction) {
    const change: MapElementDto[] = [];
    for (
      let i = 0;
      i < (editAction.editContext?.featureIndexes?.length ?? 0);
      ++i
    ) {
      const index = editAction.editContext?.featureIndexes[i];
      const mapElement = editAction.updatedData.features[
        index
      ] as MapElementDto;
      this.displayMapElements[index] = mapElement;
      change.push(mapElement);
    }

    this.rerenderMapElements();

    if (
      editAction.editType === 'addFeature' ||
      editAction.editType === 'removeFeature' ||
      editAction.editType === 'finishMovePosition' ||
      editAction.editType === 'addPosition' ||
      editAction.editType === 'removePosition'
    ) {
      this._onChange$.next(change);
    }
  }

  rerenderMapElements() {
    const layers: Layer[] = [
      ...this.createSlippyTilesLayers(),
      ...this.createLocalizationMapLayers(),
      this.createDerivedGeometryLayer(),
      this.createInteractiveGeoJsonLayer(),
      ...this.createDerivedLegendLayers(),
    ];
    this.map.setProps({ layers });
  }

  setMapElementOpacity(opacity: number) {
    if (opacity === this.mapElementOpacity) {
      return;
    }
    this.mapElementOpacity = opacity;
    this.rerenderMapElements();
  }

  getMapElementOpacity(): number {
    return this.mapElementOpacity;
  }

  setLayerOpacities(layerOpacities: Record<LayerName, number>) {
    let changed = false;
    for (const [layerName, opacity] of Object.entries(layerOpacities)) {
      const prev = this.layerStyles[layerName as LayerName].opacity;
      this.layerStyles[layerName as LayerName].opacity = opacity;
      changed ||= prev !== opacity;
    }
    if (changed) {
      this.rerenderMapElements();
    }
  }

  getLayerOpacities(): Record<LayerName, number> {
    return Object.fromEntries(
      Object.entries(this.layerStyles).map(([layerName, { opacity }]) => [
        layerName,
        opacity,
      ]),
    ) as Record<LayerName, number>;
  }

  private createDerivedGeometry(mapElement: MapElementDto): {
    type: 'Feature';
    properties: any;
    geometry: any;
  }[] {
    const features: {
      type: 'Feature';
      properties: any;
      geometry: any;
    }[] = [];

    const props = mapElement.properties;
    if (
      props &&
      mapElement.geometry.type === 'LineString' &&
      'startWidth' in props &&
      'endWidth' in props &&
      (props.startWidth ?? 0) > 0 &&
      (props.endWidth ?? 0) > 0
    ) {
      const corridorPolygonCoords = generateCorridorPolygon(
        mapElement.geometry.coordinates,
        props.startWidth!,
        props.endWidth!,
      );
      const feature = polygon([corridorPolygonCoords]);
      feature.properties = { color: getCorridorColor(mapElement) };
      features.push(feature);
    }

    if (
      [
        ElementType.ROBOT_QUEUE_EDGE,
        ElementType.ROAD_EDGE,
        ElementType.CACHED_ROAD_EDGE,
      ].includes(mapElement.elementType) ||
      (props &&
        mapElement.geometry.type === 'LineString' &&
        'oneway' in props &&
        props.oneway)
    ) {
      const arrowCoords = generateOnewayArrows(
        mapElement.geometry.coordinates as number[][],
      );
      const feature = multiLineString(arrowCoords);
      feature.properties = {
        color: getLineColor(mapElement, false),
        width: 0.3,
      };
      features.push(feature);
    }

    if (isEdgeBlocked(mapElement)) {
      const points = generateBlockedEdgePoints(
        mapElement.geometry.coordinates as number[][],
      );
      const feature = multiPoint(points);
      feature.properties = {
        color: getLineColor(mapElement, false),
        radius: 1,
      };
      features.push(feature);
    }

    return features;
  }

  private isWithinDisplayAltitudeRange(m: MapElementDto): boolean {
    const coordWithinDisplayAltitudeRange = (coord: number[]) =>
      !coord[2] ||
      (coord[2] <= this.maxDisplayAltitude &&
        coord[2] >= this.minDisplayAltitude);

    switch (m.geometry.type) {
      case 'Point':
        return coordWithinDisplayAltitudeRange(m.geometry.coordinates);
      case 'LineString':
        return m.geometry.coordinates.some((c) =>
          coordWithinDisplayAltitudeRange(c),
        );
      case 'Polygon':
        return m.geometry.coordinates[0].some((c) =>
          coordWithinDisplayAltitudeRange(c),
        );
      default:
        return false;
    }
  }

  private updateDisplayMapElements() {
    const hiddenElementTypes = new Set(this.hiddenMapElementTypes);
    if (this.zoom <= zoomThreshold) {
      hiddenElementTypes.add(ElementType.NODE);
      hiddenElementTypes.add(ElementType.TRAFFIC_LIGHT);
      hiddenElementTypes.add(ElementType.MUTEX);
      hiddenElementTypes.add(ElementType.APRIL_TAG);
      hiddenElementTypes.add(ElementType.HANDOVER_LOCATION);
      hiddenElementTypes.add(ElementType.INFRASTRUCTURE);
    }

    const isHidden = (m: MapElementDto) =>
      hiddenElementTypes.has(m.elementType);

    this.displayMapElements = this.interactiveMapElements.filter(
      (m) => !isHidden(m) && this.isWithinDisplayAltitudeRange(m),
    );

    this.derivedFeatures = this.displayMapElements.flatMap((m) =>
      this.createDerivedGeometry(this.transformAltitude(m)),
    );
    this.rerenderMapElements();
  }
}
