import { type Remote, wrap, transfer } from 'comlink';
import { DecodeWorker } from './loadTile.worker';

let counter = 0;
let workers: (Remote<DecodeWorker> | undefined)[] = [undefined, undefined];

function createWorker() {
  const index = counter % workers.length;
  try {
    const worker = workers[index];
    if (worker) {
      return worker;
    }
    const newWorker = wrap<DecodeWorker>(
      new Worker(new URL('./loadTile.worker.ts', import.meta.url), {
        type: 'module',
      }),
    );
    workers[index] = newWorker;
    return newWorker;
  } finally {
    counter++;
  }
}

export const loadTileInWorker: DecodeWorker['loadTile'] = async (...args) => {
  const result = await createWorker().loadTile(...args);
  return transfer(
    result,
    result?.tileLevels.flatMap(({ heightMap, textureLayers }) => [
      heightMap.data.buffer,
      ...textureLayers.map(({ data }) => data.buffer),
    ]) ?? [],
  );
};
