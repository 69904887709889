import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import {
  firstValueFrom,
  lastValueFrom,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { visiblePageTimer } from 'src/utils/page-visibility';
import { UsersService } from '../users.service';
import { BackendService } from '../backend.service';

@Injectable({
  providedIn: 'root',
})
export class ClockingService {
  clockedInAt$: Observable<Date | undefined>;
  private refreshUser$ = new Subject<void>();

  constructor(
    private auth: AuthService,
    private usersService: UsersService,
    private backendService: BackendService,
  ) {
    this.clockedInAt$ = merge(
      visiblePageTimer(0, 10000),
      this.refreshUser$,
    ).pipe(
      withLatestFrom(this.auth.user$),
      switchMap(([_, user]) => {
        if (!user) {
          return of(undefined);
        }
        return this.usersService.getUser(user._id);
      }),
      map((user) =>
        user?.clockedInAt ? new Date(user?.clockedInAt) : undefined,
      ),
      shareReplay(1),
    );
  }

  async clockIn() {
    const authUser = await firstValueFrom(this.auth.user$);
    if (!authUser) {
      return;
    }
    await lastValueFrom(
      this.backendService.post(`/users/${authUser._id}/clock-in`, {}),
    );
    this.refreshUser$.next();
  }

  async clockOut() {
    const authUser = await firstValueFrom(this.auth.user$);
    if (!authUser) {
      return;
    }
    await lastValueFrom(
      this.backendService.post(`/users/${authUser._id}/clock-out`, {}),
    );
    this.refreshUser$.next();
  }
}
